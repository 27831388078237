<template>

  <v-menu :close-on-content-click="false" v-model="showDatePicker" :nudge-right="40" transition="scale-transition" max-width="290px"
    min-width="290px">
    <template v-slot:activator="{ on }">
      <v-text-field v-mask="'##.##.####'"
        :rules="[v=>(v==null||v.length===0||localValueToDateString(v)!=null)]"
        ref="textfield"
        v-model="localValue"
        @click:prepend-inner="on.click"  filled outlined
        @click:clear="clear"
        :label="label" prepend-inner-icon="mdi-calendar-month-outline"
        @input="textFieldInput">
      </v-text-field>
    </template>
    <v-date-picker locale="ru-RU" :value="value" @input="pickerInput" :min="minDate" :max="maxDate">
    </v-date-picker>
  </v-menu>

</template>

<script>
export default {
  name: "DateField",
  props: ['value', 'label', 'minDate', 'maxDate'],
  data() {
    return {
      localValue: '',
      showDatePicker: false,
      dateMask: '##.##.####'
    };
  },
  methods: {
    toDateString: function (date) {
      if (date == null) {
        return ''
      }
      let mdate = new Date(date)
      let options = { timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric' }
      return mdate.toLocaleDateString('ru-RU', options)
    },

    //02.03.2022 -> 2022-03-02
    localValueToDateString(v){
      if(v == null || v.length != 10)
        return null
      let res = v.charAt(6)+v.charAt(7)+v.charAt(8)+v.charAt(9)+'-'+v.charAt(3)+v.charAt(4)+'-'+v.charAt(0)+v.charAt(1)
      let date = new Date(res)
      if(isNaN(date) || date < new Date(this.minDate) || date > new Date(this.maxDate))
        return null
      return res
    },

    pickerInput(val){
      this.showDatePicker = false
      this.localValue = this.toDateString(val)
      this.$emit('input', val)
    },

    textFieldInput(val){
      let dateString = this.localValueToDateString(val)
      if(val==null || val.length===0 || dateString!=null){
        this.$emit('input', dateString)
      } else {
        this.$emit('input', null)
      }
    },

    clear(){
      this.localValue = ''
      this.$emit('input', null)
    }
  },
  beforeMount() {
    this.localValue = this.toDateString(this.value)
  },
  beforeUpdate() {
    const textEl = this.$refs.textfield
    this.localValue = this.toDateString(this.value)
    if(this.localValue === '') {
      textEl.clearableCallback()
    }
  },
}
</script>

<style scoped>
</style>
